import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { TopCategoriesStyles } from '../../styles/homePage/TopCategoriesStyles';
// import CategoryGrid from '../category/CategoryGrid';
import ServiceGrid from '../category/ServiceGrid';
import ParagraphText from '../typography/ParagraphText';
import { SectionTitle } from '../typography/Title';

function TopCategories() {
  const data = useStaticQuery(graphql`
    {
      allSanitySpotlight(filter: { _id: { eq: "spotlightItems" } }) {
        nodes {
          category {
            id
            title
            slug {
              current
            }
            _rawDescription
          }
        }
      }
    }
  `);
  const services = data.allSanitySpotlight.nodes[0].category;
  return (
    <TopCategoriesStyles>
      <SectionTitle>Our Fee Structure</SectionTitle>
      <ParagraphText className="center-text">
        At ENERGY BROS, our fee structure is designed with your satisfaction and
        peace of mind in mind. We believe in transparency and fairness, which is
        why our fees consist of a reasonable consulting fee along with an agreed
        percentage of the credits or reversals reflected on your account. This
        approach has historically led to high levels of customer satisfaction
        and ensures that you are only charged for the results we deliver. Our
        goal is to alleviate the burden of incorrect billing from your busy
        schedule. By entrusting us with your billing challenges, you can focus
        on your core business while we work diligently to resolve any
        discrepancies on your behalf. Our approach is centered on efficiency and
        effectiveness, aiming to provide resolutions in the shortest possible
        time frame. ENERGY BROS employs a thorough process to ensure accurate
        analysis and resolution. This includes analyzing existing readings,
        conducting account reconciliations, and requesting relevant historic
        data and documentation such as job cards. Rest assured, we leave no
        stone unturned in our quest to deliver accurate and satisfactory results
      </ParagraphText>
      <SectionTitle>Top Services</SectionTitle>
      <ParagraphText>
        Discover Brilliance: Your journey starts here with Energy Bros
      </ParagraphText>
      <ServiceGrid services={services} />
    </TopCategoriesStyles>
  );
}

export default TopCategories;
