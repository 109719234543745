import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { HeroSectionStyles } from '../../styles/homePage/HeroSectionStyles';
import ParagraphText from '../typography/ParagraphText';
import Button from '../buttons/Button';
import { SectionTitle } from '../typography/Title';

function HeroSection() {
  return (
    <HeroSectionStyles>
      <div className="container">
        <div className="hero__wrapper">
          <div className="left">
            <h1 className="hero__heading">Energy Bros (PVT) Limited</h1>
            <ParagraphText className="hero__text">
              Welcome to ENERGY BROS - Your Premier Billing Solutions Partner.
              Empower your business with precision and efficiency. At ENERGY
              BROS, we specialize in correcting billing discrepancies with
              expertise honed over 8 years in the industry. We pride ourselves
              on our ability to decipher, rectify, and expedite billing queries
              with unmatched precision
            </ParagraphText>
            <SectionTitle>Our Expertise</SectionTitle>
            <ParagraphText className="hero__text">
              As energy specialists, ENERGY BROS boast unparalleled expertise
              honed through years of hands-on experience navigating the
              complexities of billing systems. With a proven track record of
              tackling even the most daunting discrepancies, our seasoned
              directors have mastered the art of billing resolution. We've
              cultivated strong relationships with key stakeholders in councils
              and municipalities, ensuring swift and efficient resolution of
              billing issues. When you choose ENERGY BROS, you're not just
              getting consultants – you're enlisting a team of elite billing
              professionals dedicated to delivering tailored solutions and
              revolutionizing your billing experience. Get ready to exceed
              expectations and embark on a billing revolution with ENERGY BROS
              by your side
            </ParagraphText>
            <Button to="/services" tag={Link} className="hero__button">
              Explore Our Services
            </Button>
            <ParagraphText className="featuredBlogs__text">
              Empowering Energy Solutions, One Bill at a Time!
            </ParagraphText>
          </div>
          <div className="right">
            <StaticImage
              className="hero__image"
              src="../../images/federico-beccari-ahi73ZN5P0Y-unsplash.jpg"
              alt="Energy Bros hero image"
              placeholder="blurred"
              objectPosition="50% 30%"
            />
          </div>
        </div>
      </div>
    </HeroSectionStyles>
  );
}

export default HeroSection;
