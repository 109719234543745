import styled from 'styled-components';

export const TopCategoriesStyles = styled.div`
  padding: 5rem 0;
  .info {
    max-width: 400px;
  }
  .center-text {
    text-align: center;
  }
`;
